/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import { Component, OnInit, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { CommonFuntions } from '../common/common.function';
import { CaseAssignmentService } from '../services/case-assignment.service';
import { CaseService } from '../services/case.service';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import { DataTableDirective } from 'angular-datatables';
import { CASE_ASSIGNMENT_TYPE, USER_TYPE_BY_ID } from '../constant';
import { SEARCH_OPTION_CASE_ASSIGNMENTS } from '../cases/translator-caselist/constant';
import * as moment from 'moment';


@Component({
  selector: 'app-case-assignments',
  templateUrl: './case-assignments.component.html',
  styleUrls: ['./case-assignments.component.css']
})
export class CaseAssignmentsComponent implements OnInit, AfterViewInit {

  role: string;
  user: any;
  totalCaseCount = 0;
  errorString: string;

  @ViewChildren(DataTableDirective)
  datatableElement: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};

  searchValue = '';
  clearSearch(): void{
    this.searchColumn = {
      column: '',
      value: ''
    };
    this.searchColumnName = '';
    this.searchColumnValue = '';
    this.search('');
  }
  columnOptions= {};
  selectedSearchOption: '';
  searchColumn = {column: '',value: ''};
  searchColumnName = '';
  searchColumnValue='';
  valueToSearch: string;

  constructor(
    public loginService: LoginService,
    public caseAssignmentService: CaseAssignmentService,
    public caseService: CaseService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  getColumnList(): any[] {
    return [
      {
        title: 'Id',
        name: 'id',
        orderable: true,
        render: function(_data, _type, row) {
          const { id } = row;
          const caseAssignmentid = id;
          return caseAssignmentid;
        }
      },
      {
        title: 'Case Id',
        orderable: true,
        name: 'case_id',
        render: function(_data, _type, row): string {
          const { case_id } = row;
          return case_id || 'N/A';
        }
      },
      {
        title: 'Patient',
        orderable: true,
        name: 'patient_name',
        render: function(_data, _type, row): string {
          let patientName = 'N/A';
          if (row?.case && row?.case?.patient && row?.case?.patient?.user?.full_name) patientName = row?.case?.patient?.user?.full_name;
          return patientName;
        }
      },
      {
        title: 'Assigned User',
        name: 'assigned_user',
        orderable: true,
        render: function(_data, _type, row): string {
          let userName = 'N/A';
          if (row?.user) userName = row?.user?.full_name;
          return userName;
        }
      },
      {
        title: 'Assigned User Role',
        name: 'user_type_id',
        orderable: true,
        render: function(_data, _type, row): string {
          return row?.user_type_id ? USER_TYPE_BY_ID[row?.user_type_id] : 'N/A';
        }
      },
      {
        title: 'Assignment Type',
        name: 'assignment_type',
        orderable: true,
        render: function(_data, _type, row): string {
          const { assignment_type } = row;
          return assignment_type || 'N/A';
        }
      },
      {
        title: 'Assignment Status',
        name: 'status',
        orderable: true,
        render: function(_data, _type, row) {
          const { status } = row;
          return status || 'N/A';
        }
      },
      {
        title: 'Updated',
        name: 'updatedAt',
        orderable: true,
        render: function(_data, _type, row): string {
          const updatedAt = moment(row.updatedAt);
          const formattedDateTime = updatedAt.format('MM/DD/YYYY') + ' at ' + updatedAt.format('hh:mm a');
          const dateValue = updatedAt.isValid() ? formattedDateTime : row.updatedAt;
          return dateValue;
        }
      },
      {
        title: 'Actions',
        data: null,
        orderable: false,
        render: (_data, _type, _full) => {
          if(this.role ==='mdmanager'){
            return '<button id="editCaseAssignment" class="btn btn--green btn--mini" >View</button>';
          }
          else{
            return '<button id="editCaseAssignment" class="btn btn--green btn--mini" >Edit</button>';
          }
        }
      }
    ];
  }

  handleEvent(row: Node, data: any[] | Object): void {
    // Unbind first in order to avoid duplicate handler
    $('td', row).unbind('click');
    $('td', row).bind('click', function () {
      if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
        return;
      }
      this.router.navigate(['/case-assignments/edit', data['id']]);
    }.bind(this));
  }
  
  setDatatableOption(): void {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${CASE_ASSIGNMENT_TYPE.ALL_CASE_ASSIGNMENTS_URL}`;
    this.dtOptions = {
      ajax: {
        url: URL,
        dataSrc: function (json) {
          const { recordsTotal, data } = json;
          this.totalCaseCount = recordsTotal;
          return data;
        }.bind(this),
        type: 'POST',
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      stateSave: true,
      searching: true,
      pageLength: 10,
      order: [[0, 'DESC']],
      info: false,
      columns: this.getColumnList(),
      rowCallback: this.handleEvent.bind(this),
      language: {
        emptyTable: 'No case assignment found'
      },
    };
  }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    if (!this.user) {
      throw new Error('No user logged in');
    }
    this.role = this.user.user_type.type.toLowerCase();
    this.searchColumn = { column: '', value: '' };
    this.columnOptions = (SEARCH_OPTION_CASE_ASSIGNMENTS[`${this.role}`]);
    this.setDatatableOption();
  }

  ngAfterViewInit(): void {
    this.clearSearch();
  }

  currentSearch(columnKey: string, columnValue: string, value: string): void {
    this.searchColumn.column = columnKey;
    this.searchColumn.value = value.toLowerCase();
    this.searchColumnName = columnValue;
    this.searchColumnValue = value;
    this.search(JSON.stringify(this.searchColumn));
  }

  search(search: string): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      return;
    }
    const dTables = this.datatableElement.toArray();
    dTables.forEach(each => {
      each.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.search(search).draw();
        }
      );
    });
  }

  datatablesError(xhr, _error, _thrown): void {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred';
    }
  }
}