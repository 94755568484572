/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PartnersModel } from '../../../domains/partnersModel';
import { PartnerDashboardModel } from '../../../domains/partnerDashboardModel';
import { CasePartnerModel } from '../../../domains/casePartnerModel';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { UserService } from '../../../services/userservice.service';
import { PartnerService } from '../../../services/partner.service';
import { CommonFuntions } from '../../../common/common.function';
import { CareTeamDashboardModel } from '../../../domains/careTeamDashboardModel';
import { PartnerCareTeamDashboardModel } from '../../../domains/partnerCareTeamDashboardModel';
import { PopUpService } from '../../../services/pop-up.service';
import { OnDestroy } from '@angular/core';
import {  Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-partner-dashboard',
  templateUrl: './partner-dashboard.component.html',
  styleUrls: ['./partner-dashboard.component.css']
})
export class PartnerDashboardComponent implements OnInit, OnDestroy {

  operation: string;
  partnerModel = new PartnersModel();
  partnerDashboardModel = new PartnerDashboardModel();
  careTeamDashboardModel = new CareTeamDashboardModel();
  partnerCareTeamDashboardModel = new PartnerCareTeamDashboardModel();
  casePartnerModel = new CasePartnerModel();
  notification_email_list: string[];
  exportMode = false;

  careteams: any;
  careteamsId: any;
  partnercareteams: any;
  partnercareteamsId: any;

  memberCount: number;
  patientCount: number;
  casesCount: number;
  submitted_request_actual_consult: string;
  first_availibility_patient_actual_consult: string;
  actual_consult_final_report: string;

  @ViewChild('chooseCareTeam', {static: true}) chooseCareTeam: ElementRef;
  @ViewChild('choosePartnerCareTeam', {static: true}) choosePartnerCareTeam: ElementRef;

  // @ViewChild('deleteConfirm') deleteConfirm: ElementRef;

  // @ViewChild('searchCareTeam') searchInputC: ElementRef;
  // @ViewChild('searchPartnerCareTeam') searchInputP: ElementRef;
  searchCareTeam = new Subject<string>();
  searchPartnerCareTeam = new Subject<string>();

  partner_patients: any;


  selectedCareTeam: any;
  selectedPartnerCareTeam: any;

  cteam$: any[];
  isCareteamLoading: boolean

  profilePicPath: string;
  sub: any;
  partnerId: number;

  showAddCT: boolean;
  showAddPCT: boolean;
  role: string;

  showCRUD = false;
  isInprogress: boolean

  alive: boolean
  currentUser: any;
  constructor(
    public loginService: LoginService,
    public userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    private popUpService: PopUpService,
    private partnerService: PartnerService,
    private toastrService:ToastrService
  ) { }

  getPartnerDashboard(partner_id: number) {



    return this.partnerService.getPartnerDashboard(partner_id);
  }

  getPatients(partner_id: number) {

    return this.partnerService.getPatientsRelated(partner_id);
  }

  getAverageTurnAroundTime(partner_patients: any) {

    return this.partnerService.getAverageTurnAroundTime(partner_patients);
  }

  getCasesRelated(partner_patients: any) {

    return this.partnerService.getCasesRelated(partner_patients);
  }

  getCareTeam(partner_id: number) {

    return this.partnerService.getCareTeam(partner_id);
  }

  getPartnerCareTeam(partner_id: number) {

    return this.partnerService.getPartnerCareTeam(partner_id);
  }

  toggleExportMode(event: Event): void {
    if(event) { event.preventDefault(); }

    this.exportMode = !this.exportMode;
  }

  restorePartner(partner_id: number) {

    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {

      this.partnerService.restorePartner(partner_id) .pipe(takeWhile(() => this.alive))
        .subscribe((result) => {
          if (result) {
            const message = this.partnerDashboardModel.partner_name + ' has been restored to the system';
            this.toastrService.success(message);

            if (partner_id) {
              this.loadPartner(partner_id);
            }

          }
          
        },(error)=>{
          this.toastrService.error(error['statusText']);
        });
    }
  }

  editPartner(partner_id: number, event: Event) {
    event.preventDefault();
    this.router.navigate(['/clients/edit/' + partner_id]);
  }

  deletePartner(_partnerName: string, partner_id: number) {
  
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {

      this.partnerService.deletePartner(partner_id).pipe(
        takeWhile(() => this.alive))
        .subscribe((data)=>{
          if (data) {
            const message = this.partnerDashboardModel.partner_name + ' has been removed successfully from the system.';
            this.toastrService.success(message);
            if (partner_id) {

              this.loadPartner(partner_id);
            }
        
          }
          else{
            this.toastrService.error(data['message']);
          }
        },(error)=>{
          this.toastrService.error(error['statusText']);
        }
        );
    }
  }


  ngOnInit() {
    this.profilePicPath = '/assets/images/profile-pic-male.png';
    this.operation = 'Doctor Dashboard';
    this.selectedCareTeam = [];
    this.selectedPartnerCareTeam = [];
    this.showAddCT = false;
    this.showAddPCT = false;
    this.isInprogress = false;
    this.alive = true;
    this.notification_email_list = [];

    this.popUpService.addPopUpView('chooseCareTeam', this.chooseCareTeam);

    this.popUpService.showCareTeam.pipe(
      takeWhile(() => this.alive))
      .subscribe(
        value => {
          if (value) {
            this.cteam$ = [];
            this.isCareteamLoading = true;

            const careteamListObservable = this.userService.findCareteam(this.partnerId, 2, this.careteamsId).pipe(
              takeWhile(() => this.alive));
          
            careteamListObservable.subscribe(
              result => {
                this.cteam$ = result;

                this.cteam$.forEach((careteam: CareTeamDashboardModel) => {
                  if (careteam.avatar && careteam.avatar != null) {
                    this.getProfilePicture(careteam.user_id, careteam.avatar, this.cteam$);
                  }
                  else {
                    careteam['avatarImage'] = null;
                  }
                });
              },
              (_error) => {
                this.cteam$ = [];
                this.isCareteamLoading = false;
              },
              () => {
                this.isCareteamLoading = false;
              }
            );
          }
        }
      );

    this.sub = this.route.params
      .subscribe(params => {
        if (params['id'] && isNaN(params['id'])) {
          this.router.navigate(['/page-not-found']);
          return;
        }

        this.partnerId = +params['id'];
      });
    
    this.currentUser = this.loginService.getCurrentUser();
    
    const role = this.currentUser.user_type.type.toLowerCase();
    this.role = this.currentUser.user_type.type.toLowerCase();
    if (role == 'admin' || role == 'careteam' || role == 'cmmanager' || role == 'casecoordinator') {
      this.showCRUD = true;
    }
    if (role == 'admin' || role == 'careteam' || role == 'partner' || role == 'cmmanager' || role =='casecoordinator') {
      this.showAddCT = role == 'partner' ? false : true;
      this.showAddPCT = role == 'partner' ? false : true;
      if (this.partnerId) {
        this.partnerId = (role == 'partner' && this.currentUser.partners.length != 0) ? this.currentUser.partners[0].partner_id : this.partnerId;
        this.loadPartner(this.partnerId);
        this.loadCareTeam(this.partnerId);
        this.loadPartnerCareTeam(this.partnerId);
        this.loadPatient(this.partnerId);
        this.loadAverageTurnAroundTime(this.partnerId);
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }


  ngOnDestroy() {
    this.alive = false;
    this.popUpService.destoryConfirmDialog();
    this.popUpService.removePopUpView();
  }

  hideDiv(divName: string, event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (divName === 'chooseCareTeam') {
      // this.searchInputC.nativeElement.value = '';
      this.popUpService.showCareTeam.next(false);
    }
    else if (divName === 'choosePartnerCareTeam') {
      // this.searchInputP.nativeElement.value = '';
      this.popUpService.showPartnerCareTeam.next(false);
    }
    const div = this.popUpService.getPopUpView(divName);
    this.popUpService.hideDiv(div);
  }


  loadCareTeam(partnerId) {
    this.getCareTeam(partnerId).pipe(
      takeWhile(() => this.alive))
      .subscribe(
        result => {
          this.careteams = result['userList'] as Array<CareTeamDashboardModel>;
          this.careteamsId = result['userIds'];

          this.careteams.forEach((careteam: CareTeamDashboardModel) => {
            if (careteam.avatar && careteam.avatar != null) {
              this.getProfilePicture(careteam.user_id, careteam.avatar, this.careteams);
            }
          });
        }
      );
  }

  getProfilePicture(_user_id: number, avatar: string, userList: any[]): any {
    this.userService.getUploadedFile(avatar)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        result => {
          const careteam = userList.find(careteam => careteam.avatar == avatar);

          if (!careteam) {
            return;
          }

          careteam['avatarImage'] = result.image;
        }
      );
  }

  loadPartnerCareTeam(partnerId) {
    this.getPartnerCareTeam(partnerId).pipe(
      takeWhile(() => this.alive))
      .subscribe(
        result => {

          this.partnercareteams = result['userList'] as Array<PartnerCareTeamDashboardModel>;
          this.partnercareteamsId = result['userIds'];

          this.partnercareteams.forEach((careteam: CareTeamDashboardModel) => {
            if (careteam.avatar && careteam.avatar != null) {
              this.getProfilePicture(careteam.user_id, careteam.avatar, this.partnercareteams);
            }
            else {
              careteam['avatarImage'] = null;
            }
          });
        }
      );
  }

  loadPartner(partnerId) {
    this.getPartnerDashboard(partnerId).pipe(
      takeWhile(() => this.alive))
      .subscribe(
        result => {
          this.partnerDashboardModel = result as PartnerDashboardModel;
          if (this.partnerDashboardModel?.notification_email) {
            this.notification_email_list = this.partnerDashboardModel?.notification_email.split(',').map(each => each.trim());
          }
          else {
            this.notification_email_list = [];
          }
        }
      );
  }

  loadPatient(partnerId) {

    this.loadCases(partnerId);

    this.getPatients(partnerId).pipe(
      takeWhile(() => this.alive))
      .subscribe(result => {
        this.memberCount = result.totalMembers;
        this.patientCount = result.patientCount;
      });

  }

  loadAverageTurnAroundTime(partner_patients: any) {
    this.getAverageTurnAroundTime(partner_patients).pipe(
      takeWhile(() => this.alive))
      .subscribe(
        result => {
          this.submitted_request_actual_consult = parseFloat(result['average_submitted_request_to_consult']).toFixed(2);
          if (this.submitted_request_actual_consult == 'NaN') {this.submitted_request_actual_consult = 'N/A';}
          this.first_availibility_patient_actual_consult = parseFloat(result['average_turn_around']).toFixed(2);
          if (this.first_availibility_patient_actual_consult == 'NaN') {this.first_availibility_patient_actual_consult = 'N/A';}
          this.actual_consult_final_report = parseFloat(result['average_consult_complete_to_final_report_submit']).toFixed(2);
          if (this.actual_consult_final_report == 'NaN') {this.actual_consult_final_report = 'N/A';}
        }
      );
  }

  loadCases(partner_patients: any) {
    if (partner_patients) {
      this.getCasesRelated(partner_patients).pipe(
        takeWhile(() => this.alive))
        .subscribe(
          result => {
            this.casePartnerModel = result as CasePartnerModel;
            this.casesCount = result['total_cases'];
          }
        );
    }
  }

  addCareTeam(event: Event) {
    this.selectedCareTeam = [];
    event.preventDefault();
    const div = this.popUpService.getPopUpView('chooseCareTeam');
    this.popUpService.showCareTeam.next(true);
    this.popUpService.showDiv(div);
  }

  addPartnerCareTeam(event: Event) {
    event.preventDefault();
    this.hideDiv('chooseCareTeam');
    const div = this.popUpService.getPopUpView('choosePartnerCareTeam');
    this.popUpService.showPartnerCareTeam.next(true);
    this.popUpService.showDiv(div);
  }


  assignCareTeamToThisPartner(event: Event, partner_id: number) {
    event.preventDefault();
    if (this.selectedCareTeam.length > 0) {
      if (CommonFuntions.guardForAction(this.loginService,
        this.router)) {
        this.partnerService.assignCareTeamToThisPartner(partner_id, this.selectedCareTeam).pipe(
          takeWhile(() => this.alive))
          .subscribe(
            function(result){
              if (result) {
                // alert('Careteam member has been sucessfully added to this partner');
                const message = 'Careteam member has been sucessfully added to this client.';
                // show alert
                this.popUpService.destoryConfirmDialog();

                this.alert(message, function () {
                  this.hideDiv('alertDialog');
                });
                this.loadCareTeam(this.partnerId);
                this.hideDiv('chooseCareTeam');
              }

            }.bind(this), function(error) {
              this.alert(this.partnerService.handleError(error, this.operation.toLowerCase()),							function () {
                // do nothing
                this.hideDiv('alertDialog');
              });
            }.bind(this)
          );
      }

    }
    else {
      this.alert('Please select the careteam to add to this client!!!',function () {
        // do nothing
        this.hideDiv('alertDialog');
      });
    }
  }

  assignPartnerCareTeamToThisPartner(event: Event, partner_id: number) {
    event.preventDefault();
    if (this.selectedPartnerCareTeam.length > 0) {
      if (CommonFuntions.guardForAction(this.loginService,
        this.router)) {
        this.partnerService.assignCareTeamToThisPartner(partner_id, this.selectedPartnerCareTeam).pipe(
          takeWhile(() => this.alive))
          .subscribe(
            result => {
              if (result) {
                // alert('Partner Careteam member has been sucessfully added to this partner');
                const message = 'Client Careteam member has been sucessfully added to this client.';
                // show alert
                this.popUpService.destoryConfirmDialog();

                this.alert(message, function () {
                  this.hideDiv('alertDialog');
                });
                this.loadPartnerCareTeam(this.partnerId);
                this.hideDiv('choosePartnerCareTeam');
              }

            }, error => {
              this.alert(this.partnerService.handleError(error, this.operation.toLowerCase()),function () {
                this.hideDiv('alertDialog');
              }.bind(this));
            }
          );
      }

    }
    else {
      this.alert('Please select the client careteam to add to this client!!!',function () {
        this.hideDiv('alertDialog');
      }.bind(this));
    }
  }



  selectThisCareTeam(event: Event, user_id: number, avatar: any) {
    event.preventDefault();
    if (this.selectedCareTeam.indexOf(user_id) !== -1) {
      this.selectedCareTeam.splice(this.selectedCareTeam.indexOf(user_id), 1);
      const thisPC = $('div[id=\'' + user_id + '\']');
      thisPC.removeClass('row--selected');
      const replaceDiv = thisPC.find('span[ class=\'icon icon--row-selected\']');
      replaceDiv.remove();
      const addSelected = thisPC.find('div[ class=\'list-row__image\']');
      if (avatar) {
        addSelected.append('<div class=\'thumb thumb--small\'><img _ngcontent-c11=\'\' src=\'data:image/jpg;base64,' + avatar + '\'></div>');
      }
      else {
        addSelected.append('<div class=\'thumb thumb--small\'><img _ngcontent-c11=\'\' src=\'/assets/images/profile-pic-male.png\'></div>');
      }


    }
    else {
      this.selectedCareTeam.push(user_id);
      const thisPC = $('div[id=\'' + user_id + '\']');
      thisPC.addClass('row--selected');
      const replaceDiv = thisPC.find('div[ class=\'thumb thumb--small\']');
      replaceDiv.remove();
      const addSelected = thisPC.find('div[ class=\'list-row__image\']');
      addSelected.append('<span class=\'icon icon--row-selected\'></span>');
    }
  }

  delete_Confirm(partnerName: string, partnerId: number, event: Event) {
    event.preventDefault();
    this.popUpService.destoryConfirmDialog(); // destory if any

    const confirmMessage = 'Are you sure you want to remove ' + partnerName + ' from the system?';
    this.popUpService.setDialogParams(
      'Delete Client',
      confirmMessage,
      'Delete',
      'Cancel',
      function () {
        this.component.deletePartner(this.partnerName, this.id);
        this.component.popUpService.hideDiv('confirmDialog');
      }.bind({
        id: partnerId,
        name: partnerName,
        component: this
      }),
      function () {
        // do nothing
        this.hideDiv('confirmDialog');
      }
    );
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  alert(message: string, callback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );
    const div = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.setPositiveAction(callback);
    this.popUpService.showDiv(div);
  }

  confirm(message: string, positiveCallback: Function, negativeCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Delete Confirmation',
      message,
      'Yes',
      'No',
      positiveCallback,
      negativeCallback
    );
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  removeThisCareTeam(event: Event, user_id: number, partner_id: number, careteam_name: string, removing_name: string) {
    event.preventDefault();
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {

      const onPostiveAction = function () {
        this.isInprogress = true;

        this.partnerService.removeCareTeam(user_id, partner_id)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            result => {
              if (result) {
                const message = careteam_name + ': ' + removing_name + ' has been removed from this client.';
                // show alert

                this.alert(message, function () {
                  this.hideDiv('alertDialog');
                });
                // alert('Careteam has been removed from this partner');
                if (this.partnerId) {
                  this.loadCareTeam(this.partnerId);
                  this.loadPartnerCareTeam(this.partnerId);
                }
              }

              this.isInprogress = false;

            }, error => {
              this.alert(this.partnerService.handleError(error, this.operation.toLowerCase()));

              this.isInprogress = false;
            }
          );

        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);

      const onNegativeAction = function () {
        this.hideDiv('confirmDialog');
      };

      this.confirm(
        'Are you sure you want to delete \'' + removing_name + '\' from the system?',
        onPostiveAction,
        onNegativeAction
      );
    }
  }

  viewUserInfo(event: Event, user_id: number) {
    event.preventDefault();
    this.router.navigate(['/profile', user_id]);

  }
}
