/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import {mergeMap, takeWhile,  catchError } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, NgForm } from '@angular/forms';
import { UserService } from '../../services/userservice.service';
import { FileUploader } from 'ng2-file-upload';

import * as moment from 'moment/moment';
import { CommonService } from '../../services/common.service';
import { FormControl } from '@angular/forms';
import { CommonFuntions } from '../../common/common.function';
import { CaseService } from '../../services/case.service';
import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';
import { PopUpService } from '../../services/pop-up.service';
import { ViewChild, ElementRef } from '@angular/core';
import { DoctorService } from '../../services/doctor.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { PartnerService } from '../../services/partner.service';

@Component({
  selector: 'app-create-case',
  templateUrl: './create-case.component.html',
  styleUrls: ['./create-case.component.css']
})
export class CreateCaseComponent implements OnInit, OnDestroy {
  private alive: boolean
  @ViewChild('document', {static: true}) documentInput: ElementRef;
  @ViewChild('doctorProfile', {static: true}) doctorProfile: ElementRef
  caseForm: FormGroup;
  isProgress: boolean;

  patient: any;
  avatarMale: string;
  step;
  isSelected;
  isPublicallyListed;
  role;
  case_id;

  caseType;
  importance;
  categories;
  category_fields;
  md_types;
  partner_id;
  isDocumentTypeInprogress: boolean

  public uploader: FileUploader;
  public hasBaseDropZoneOver = false;
  private uploads = [];

  doctorsList;
  selectedDoctor;

  public isHasLikeDoctor;

  public caseDetails;
  public partnerDetails;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private reasonList = [
    {
      key: 'Select',
      value: 'select'
    }
  ];
  private available_dates: any;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private isCalenderLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private types = [
    {
      key: 'Select',
      value: 'SELECT'
    }
  ];

  private listOfMime = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/tif',
    'image/bmp',
    'application/pdf'
  ]
  currentDate

  selectedDoctorInfo: any
  selectedDoctorBoardCertification: any
  selectedDoctorCondition: any
  selectedDoctorAvatarImage: any

  selectedDoctorInfo$: ISubscription
  selectedDoctorBoardCertification$: ISubscription
  selectedDoctorCondition$: ISubscription
  selectedDoctorAvatarImage$: ISubscription
  agreementShowAll: boolean
  communicationMode: any[];
  preferredTime: any[];
  loadingImage = true;
  user: any;
  userId: number;
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private commonService: CommonService,
    private caseService: CaseService,
    private popUpService: PopUpService,
    private doctorService: DoctorService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private partnerService: PartnerService,
  ) { }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    this.initView();
    this.avatarMale = 'assets/images/avatar-male.jpg';
    this.route.params.pipe(takeWhile(
      () => this.alive
    )).subscribe(
      params => {
        this.userId = +params['patientid'];
        if (params['patientid'] && isNaN(params['patientid'])) {
          this.router.navigate(['/page-not-found']);
          return;
        }
        this.checkUserRole(
          this.loginService.getCurrentUser(),
          this.userId
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.popUpService.removePopUpView();
    this.alive = false;
  }

  initView() {
    this.alive = true;
    this.step = 1;
    this.isSelected = false;
    this.isPublicallyListed = true;
    this.isProgress = true;
    this.isHasLikeDoctor = true;
    this.isCalenderLoading = false;
    this.partner_id = null;
    this.currentDate = new Date();
    this.currentDate.setHours(0, 0, 0, 0);

    this.setInitDropDown();

    this.getCaseType();
  }

  onAccept(event: Event) {
    event.preventDefault();

    if (
      CommonFuntions.guardForAction(
        this.loginService,
        this.router
      ) &&
      this.caseForm.valid
    ) {

      if (this.role != null && this.role.toLowerCase() === 'partner') {
        this.step = 2;
        this.isDocumentTypeInprogress = true;
        this.getDocumentType();
        this.uploader = this.getUploader(this.case_id);
        this.addOptionToUploader(this.uploader);

        this.caseForm.addControl(
          'case_documents',
          new FormArray([])
        );

      }
      else if (this.role != null && (this.role.toLowerCase() === 'careteam' || this.role.toLowerCase() === 'cmmanager' || this.role?.toLowerCase()==='casecoordinator')) {
        this.onSubmit(event);
      }
      else if (this.role != null && this.role.toLowerCase() === 'patient') {
        this.onSubmit(event);
      }

    }
    else {
      CommonFuntions.validateAllFormFields(this.caseForm);
    }
  }

  getPartner(partner_id: number) {
    return this.partnerService.getPartner(partner_id);
  }
  
  getPartnerDetails(){
    this.getPartner(this.partner_id).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      result => {
        this.partnerDetails = result;
      }
    );
  }

  getUserPartnerDetail(user_id: number): void {
    this.partnerService.getUserPartner(user_id).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      result => {
        this.partnerDetails = result;
      }
    );
  }

  checkUserRole(user: any, userId: number) {
    let role;
    if (user && user.user_type && user.user_type.type) {
      role = user.user_type.type;
      this.role = role;
    }
    if(role.toLowerCase() !== 'cmmanager'){
      this.partner_id = user.partners[0].partner_id;
    }
    this.caseForm = this.buildForm(role.toLowerCase());
    if (
      role &&
      (
        role.toLowerCase() !== 'careteam'
        && role.toLowerCase()!=='casecoordinator'
        && role.toLowerCase() !== 'cmmanager'
        && role.toLowerCase() !== 'partner'
        && role.toLowerCase() !== 'patient'
      )
    ) {
      this.router.navigate(['/']);
      return;
    }
  
    if (role && role.toLowerCase() === 'patient') {
      if (user.user_id != userId) {
        this.router.navigate(['/']);
        return;
      }
    }
    this.getPatient(userId);
    
    if (
      this.role &&
      (
        this.role.toLowerCase() === 'careteam' ||
        this.role.toLowerCase() === 'cmmanager' ||
        this.role.toLowerCase() === 'partner'  ||
        this.role.toLowerCase()==='casecoordinator'
      )
    ) {
      this.getImportance();
      this.getMdType();
  
      // this.getPartnerDetails();
      this.getUserPartnerDetail(userId);
  
      this.caseForm.get('category_field_id').disable();
    }
  
    if (
      this.role &&
      this.role.toLowerCase() === 'patient'
    ) {
      this.getCommunicationMode();
      this.getPreferredTime();
    }
  
    this.uploader = this.getUploader(this.case_id);
    this.addOptionToUploader(this.uploader);
  }

  private buildForm(role: string): FormGroup {

    if (role === 'careteam' || role === 'partner' || role === 'cmmanager' || role ==='casecoordinator') {

      return this.formBuilder.group(
        {
          case_type: ['select', CommonFuntions.validateDropDown],
          importance: ['select', CommonFuntions.validateDropDown],
          earliest_available_date: [null],
          category_id: [0, CommonFuntions.validateDropDown],
          category_field_id: [0, CommonFuntions.validateDropDown],
          md_type_id: ['select', CommonFuntions.validateDropDown],
          is_vip: false,
          interpreter_needed: false,
          translator_needed: false,
          patient_id: null,
          agreement: [false, CommonFuntions.isChecked],
          case_questions: this.formBuilder.array([
            this.getQuestionForm()
          ]),
          patient_diagnosis: [null, [Validators.required, Validators.maxLength(50000)]],
          case_additional_note: [null, Validators.maxLength(50000)],
          allyCaseNumber: [null, Validators.maxLength(50000)]
        }
      );

    }
    else {
      return this.formBuilder.group({
        case_patient_note: [null, [Validators.required, Validators.maxLength(50000)]],
        patient_diagnosis: [null, Validators.maxLength(50000)],
        agreement: [false, CommonFuntions.isChecked],
        case_questions: this.formBuilder.array([
          this.getQuestionForm()
        ]),
        case_type: ['select', CommonFuntions.validateDropDown],
        preferred_communication_mode: ['SELECT', CommonFuntions.validateDropDown],
        best_communication_time: [null],
        category_id: [0],
        phone_number: [null],
        allyCaseNumber: [null, Validators.maxLength(50000)]
      });
    }
  }

  private getQuestionForm(event?: Event) {
    if (event != null) {
      event.preventDefault();
    }

    if (this.caseForm == null) {
      return this.formBuilder.group({
        question_text: [null, [Validators.required, Validators.maxLength(50000)]]
      });
    }

    const questionArray = this.caseForm.get('case_questions') as FormArray;

    if (questionArray.controls.length < 1) {
      return this.formBuilder.group({
        question_text: [null, Validators.required]
      });
    }
    else {
      return questionArray.push(
        this.formBuilder.group({
          question_text: [null, Validators.required]
        })
      );
    }

  }

  removeQuestionAtIndex(index: number, event?: Event) {
    if (event != null) {
      event.preventDefault();
    }

    const question = (this.caseForm.get('case_questions') as FormArray).controls[index];
    const value = question.get('question_text').value;
    if (value == null || value === '') {
      (this.caseForm.get('case_questions') as FormArray).removeAt(index);
      return;
    }
    const confirmStr = 'Are you sure you want to delete question "' + value + '"';
    if (confirm(confirmStr)) {
      (this.caseForm.get('case_questions') as FormArray).removeAt(index);
    }
  }

  private getPatient(id: number) {
    this.userService.getUser(id).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      user => {
        this.patient = user;

        const year = moment().diff(moment(user.patient_detail.dob), 'years');

        this.patient['age'] = year;

        if (
          this.role &&
          (
            this.role.toLowerCase() === 'careteam' ||
            this.role.toLowerCase() === 'cmmanager' ||
            this.role.toLowerCase() === 'partner' ||
            this.role.toLowerCase()==='casecoordinator'
          )
        ) {
          this.caseForm.get('patient_id').setValue(user.patient_detail.patient_id);
          this.getCategory(year);
        }
        else if (this.role.toLowerCase() === 'patient') {
          this.getCategory(year);
        }
      },
      (_error) => {
        this.router.navigate(['/']);
      },
      () => {
        this.isProgress = false;
      }
    );
  }

  private setInitDropDown() {

    this.importance = [
      {
        key: 'Select',
        value: 'select'
      }
    ];
    this.categories = [{
      category_id: 0,
      category: 'Select'
    }];
    this.caseType = this.importance.slice();

    this.category_fields = [{
      category_field_id: 0,
      category_field: 'Select'
    }];

    this.md_types = [
      {
        md_type: 'Select',
        md_type_id: 'select'
      }
    ];

    this.communicationMode = [
      {
        key: 'Select',
        value: 'SELECT'
      }
    ];
  }

  private getCaseType() {
    const user = this.loginService.getCurrentUser();
    const role = user.user_type.type.toLowerCase();
    this.commonService.getCaseType().pipe(takeWhile(
      () => this.alive
    )).subscribe(
      types => {
        types.forEach(
          type => {
            this.caseType.push(type);
            if (
              role.toLowerCase() !== 'patient'
            ) {this.caseType = this.caseType.filter(type => type.value!=='NO PREFERENCE');} 
          }
        );
      }
    );
  }

  private getImportance() {
    this.commonService.getImportance().pipe(takeWhile(
      () => this.alive
    )).subscribe(
      importance => {
        importance.forEach(
          important => {
            this.importance.push(important);
          }
        );
      }
    );
  }

  private getCommunicationMode() {
    this.caseService.preferredCommunicationMode().pipe(takeWhile(
      () => this.alive
    )).subscribe(
      communication => {
        communication.forEach(
          each => {
            this.communicationMode.push(each);
          }
        );
      }
    );
  }

  private getPreferredTime() {
    this.caseService.bestCommunicationTime().pipe(takeWhile(
      () => this.alive
    )).subscribe(
      time => {
        this.preferredTime = time;
      }
    );
  }

  private getCategory(age: number) {
    this.commonService.getCategory(age).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      ageGroups => {
        ageGroups.categories.forEach(
          category => {
            this.categories.push(category);
          }
        );
      }
    );
  }

  public onCategorySelect(index: number) {
    if (index < 1) {
      this.category_fields = [{
        category_field_id: 0,
        category_field: 'Select'
      }];

      if (this.caseForm.get('category_value')) {this.caseForm.removeControl('category_value');}
    }
    else {
      if (!this.caseForm.get('category_value')) {
        this.caseForm.addControl(
          'category_value',
          new FormControl(this.categories[index].category)
        );
      }
      else {
        this.caseForm.get('category_value').setValue(
          this.categories[index].category
        );
      }

      this.category_fields = [];
      const category_fields = this.categories[index].category_fields.slice(0);
      this.category_fields = category_fields;
      this.category_fields.splice(0, 0, { category_field_id: 0, category_field: 'Select' });
    }

    this.caseForm.get('category_field_id').reset();
    if (index < 1 || this.category_fields.length < 2) {
      this.caseForm.get('category_field_id').disable();
    }
    else {
      this.caseForm.get('category_field_id').enable();
    }
  }

  public onCategoryFieldSelect(index: number) {
    if (index < 1) {
      if (this.caseForm.get('category_field_value')) {this.caseForm.removeControl('category_field_value');}
    }
    else {
      if (!this.caseForm.get('category_field_value')) {
        this.caseForm.addControl(
          'category_field_value',
          new FormControl(this.category_fields[index].category_field)
        );
      }
      else {
        this.caseForm.get('category_field_value').setValue(
          this.category_fields[index].category_field
        );
      }
    }
  }

  private getMdType() {
    this.commonService.getMdType().pipe(takeWhile(
      () => this.alive
    )).subscribe(
      mdTypes => {
        mdTypes.forEach(
          mdType => {
            this.md_types.push(mdType);
          }
        );
      }
    );
  }

  private addOptionToUploader(uploader: FileUploader) {
    uploader.onAfterAddingFile = function (file) {
      file.withCredentials = false;

      if (uploader.queue.length > 1) {
        uploader.queue.splice(0, 1);
      }

      setTimeout(
        function () {
          this.documentInput.value = null;
        }.bind(this)
        , 300
      );
    }.bind(this);

    uploader.onCompleteItem = function (_item, response, status, _header) {
      if (response == null || response === '') {return;}

      if (status != 200) {
        const error = JSON.parse(response);
        this.showAlert(error.msg);
      }
      else {

        const case_documents = this.caseForm.get('case_documents') as FormArray;

        const resObj = [JSON.parse(response)];
        const reverseUploads = this.uploads.slice(0).reverse();
        const index = reverseUploads.findIndex(uploaderUploader => uploaderUploader.type == 'file' && uploaderUploader.uploader.queue[0].file.name.toLowerCase() === resObj[0].document_name.toLowerCase());

        if (index < 0) {
          return;
        }

        const uploadsCount = this.uploads.length - 1;
        const finalIndex = uploadsCount - index;

        resObj[0]['document_type'] = this.uploads[finalIndex].documentType;

        if (case_documents != null && resObj.length > 0) {
          case_documents.push(new FormControl(resObj[0]));
        }
      }
    }.bind(this);

    uploader.onErrorItem = function (item, _response, _status, _header) {
      const errorFileName = item.file.name;
      const index = this.uploads.findIndex(upload => {
        if (!upload.uploader) {return false;}

        const errorUploader = upload.uploader;

        if (errorFileName.toLowerCase() === errorUploader.queue[0].file.name.toLowerCase()) {
          return true;
        }
        else {
          return false;
        }
      });
      if (index > -1) {
        this.uploads = this.uploads.splice(index, 0);
      }
    }.bind(this);
    
    uploader.onWhenAddingFileFailed = function (_item, filter, _option) {
      switch (filter.name) {
      case 'mimeType':
        this.showAlert('System does not support this type of file format');
        this.documentInput.nativeElement.value = '';
        break;
      case 'fileSize':
        this.showAlert('Files to be uploaded must be less than of size 50 MB');
        this.documentInput.nativeElement.value = '';
        break;
      default:
        break;
      }
    }.bind(this);
  }

  private getUploader(_caseId: number): FileUploader {
    const urlString = ConfigAssetLoaderService.getApiURL() + '/case/upload';
    const token = localStorage.getItem('token');
    return new FileUploader(
      {
        url: urlString,
        itemAlias: 'documents',
        authToken: 'Bearer ' + token,
        queueLimit: 1,
        allowedMimeType: this.listOfMime,
        maxFileSize: 50 * 1024 * 1024
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private getReadableFileSizeString(fileSizeInBytes) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }

  public continue(event) {
    event.preventDefault();
    const fileUploads = this.uploads.filter(eachUploads => {
      if (!eachUploads.uploader) {
        return false;
      }

      const fileUploader: FileUploader = eachUploads.uploader;
      return fileUploader.isUploading;
    });

    if (fileUploads.length > 0) {
      this.showAlert('File is uploading');
      
      return;
    }
    this.step = 3;

    this.caseForm.addControl(
      'selected_doctor_id',
      new FormControl(null)
    );

    this.caseForm.addControl(
      'doctor_availability',
      new FormArray([])
    );

    this.getDoctorsByMd();


  }

  private getDoctorsByMd() {
    this.isProgress = true;

    if (!this.caseForm &&
      this.caseForm == null &&
      this.caseForm.get('category_field_id')) {
      return;
    }

    const category_field_id = this.caseForm.get('category_field_id').value;
    const user = this.loginService.getCurrentUser();

    if (category_field_id == null || category_field_id == 0) {
      this.doctorsList = [];
      this.isProgress = false;
    }
    else {
      if(category_field_id){
        this.userService.findDoctorBySubSpecility(category_field_id, user.user_id).pipe(takeWhile(
          () => this.alive
        )).subscribe(
          doctors => {
            this.doctorsList = doctors;
            this.doctorsList.map((item, i) => {
              this.doctorsList[i]['loading'] = true;
              this.selectedDoctorAvatarImage$ = this.userService.getUploadedFile(item.doctor_info.user.avatar).subscribe(
                image => {
                  if(image){
                    this.loadingImage = false;
                    item.doctor_info.user['avatarFallback'] = null;
                    this.doctorsList[i]['loading'] = false;
                    return item.doctor_info.user.avatar = image;
                  }
                  else{
                    item.doctor_info.user.avatar = null;
                    this.doctorsList[i]['loading'] = false;
                    return item.doctor_info.user['avatarFallback'] = 'assets/images/avatar-male.jpg';
                    
                  }
                },
                (_error)=>{
                  item.doctor_info.user['avatarFallback'] = 'assets/images/avatar-male.jpg';
                  this.doctorsList[i]['loading'] = false;
                }
              );
            });
            this.doctorsList = doctors;
          },
          (_error) => {
            this.doctorsList = [];
          },
          () => {
            this.isProgress = false;
          }
        );
      }

    }
  }

  public selectDoctor(doctor, event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.isSelected = true;
    this.selectedDoctor = doctor;

    const selected_doctor = this.caseForm.get('selected_doctor_id');

    if (selected_doctor == null) {
      this.caseForm.addControl('selected_doctor_id', new FormControl(this.selectedDoctor.doctor_info.doctor_id));
    }
    else {
      selected_doctor.setValue(this.selectedDoctor.doctor_info.doctor_id);
    }

    // this.isPublicallyListed = this.selectedDoctor.doctor_info.publicly_listed;
    if (true) {
      const date = moment().format('YYYY-MM-DD');
      this.getDoctorAvailableDates(this.selectedDoctor.doctor_info.doctor_id, date);
    }
  }

  public getDoctorProfile(userid: number) {
    this.selectedDoctorInfo$ = this.userService.getDoctorProfile(userid).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      doctor => {
        this.selectedDoctorInfo = doctor;
        if (doctor.avatar && doctor.avatar != null) {
          this.getDoctorAvatarImage(doctor.avatar);
        }
      }
    );
  }

  private getDoctorBoardCertification(userid: number) {
    this.selectedDoctorBoardCertification$ = this.userService.getDoctorCertifications(userid).subscribe(
      certification => {
        this.selectedDoctorBoardCertification = certification;
      }
    );
  }

  private getDoctorCondition(userid: number) {
    this.selectedDoctorCondition$ = this.userService.getDoctorCondition(userid).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      condition => {
        this.selectedDoctorCondition = condition;
      }
    );
  }

  private getDoctorAvatarImage(avatarLocation: string) {
    this.selectedDoctorAvatarImage$ = this.userService.getUploadedFile(avatarLocation).subscribe(
      image => this.selectedDoctorAvatarImage = image
    );
  }

  public viewDoctorCard(doctor: any) {
    this.selectedDoctorAvatarImage = null;
    this.selectedDoctorInfo = null;
    this.selectedDoctorBoardCertification = null;
    this.selectedDoctorCondition = null;

    this.doctorProfile.nativeElement.hidden = false;
    setTimeout(() => {
      this.doctorProfile.nativeElement.scrollTop = 0;
    }, 300);
    this.getDoctorProfile(doctor.doctor_info.user_id);
    this.getDoctorBoardCertification(doctor.doctor_info.user_id);
    this.getDoctorCondition(doctor.doctor_info.user_id);
  }

  public unSelectDoctor(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.isSelected = false;
    this.selectedDoctor = null;

    const selected_doctor = this.caseForm.get('selected_doctor_id');

    if (selected_doctor != null) {
      selected_doctor.setValue(null);
    }
  }

  private onSubmit(event?: Event) {
    if (event && event != null) {
      event.preventDefault();
    }

    if (
      CommonFuntions.guardForAction(
        this.loginService,
        this.router
      ) &&
      this.caseForm.valid
    ) {
      this.isProgress = true;

      if (
        (this.role.toLowerCase() === 'careteam' || this.role.toLowerCase() === 'partner' || this.role.toLowerCase() === 'cmmanager' || this.role?.toLowerCase()==='casecoordinator') &&
        this.available_dates &&
        this.available_dates.length > 0
      ) {
        if (!this.caseForm.contains('doctor_availability')) {this.caseForm.addControl('doctor_availability', new FormArray([]));}
        else {(this.caseForm.get('doctor_availability') as FormArray).controls = [];}

        const doctor_availability = this.getSelectedTime();


        doctor_availability.forEach(eachTime => {
          const obj = {};
          obj['start_date_time'] = eachTime.start_date_time;
          obj['end_date_time'] = eachTime.end_date_time;

          (this.caseForm.get('doctor_availability') as FormArray).push(new FormControl(obj));
        });
      }

      if (this.role.toLowerCase() === 'patient') {
        const controlCategoryId = this.caseForm.get('category_id');

        if (controlCategoryId && controlCategoryId != null) {
          const value = controlCategoryId.value;

          if (value == 0 || value === '0' || value === null) {
            this.caseForm.removeControl('category_id');
          }
        }
      }

      this.caseService.addCase(this.caseForm.value).pipe(takeWhile(
        () => this.alive
      )).pipe(
        catchError(error => {

          if (error.error.name == 'SequelizeUniqueConstraintError') {
            error = new Error(error.error.message);
            error.name = 'SequelizeUniqueConstraintError';
          }
          else {
            error = new Error('Cannot create case');
            error.name = 'caseCreateError';
          }

          throw (error);
        })
      ).pipe(mergeMap(
        caseInfo => {
          this.case_id = caseInfo.id;
          this.step = 4;

          const user = this.loginService.getCurrentUser();
          return this.caseService.getCaseById(
            caseInfo.id,
            user.user_id
          ).pipe(takeWhile(
            () => this.alive
          )).pipe(
            catchError(error => {
              error = new Error('Failed to retrive case details');
              error.name = 'caseDetailError';

              throw error;
            })
          );
        }
      )).subscribe(
        details => {
          this.caseDetails = details;
          this.isProgress = false;
        },
        error => {
          let message = 'Cannot create case';
          if (error && error.message) { message = error.message; }
          this.isProgress = false;
          this.showAlert(
            message,
            function () {
              // this.router.navigate(['/']);
              this.popUpService.hideDiv('alertDialog');
            }.bind(this)
          );
        }
      );
    }
    else {
      CommonFuntions.validateAllFormFields(this.caseForm);
    }
  }

  public onReturnToCaseList(event?: Event) {

    if (event) {
      event.preventDefault();
    }
    this.router.navigate(['/case']);
  }

  public onGoToCase(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (this.case_id && this.case_id != null) {
      this.router.navigate(['/case', this.case_id]);
    }
    else {
      this.showAlert('No id for case found');
    }
  }

  public doNotLikeDoctor(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    this.isHasLikeDoctor = false;

    this.caseForm.addControl('reason_id', new FormControl('select', CommonFuntions.validateDropDown));
    this.caseForm.addControl('reason_additional_note', new FormControl('', Validators.maxLength(50000)));

    // this.isProgress = true;
    this.commonService.getCaseReason().pipe(takeWhile(
      () => this.alive
    )).subscribe(
      function (reasons) {
        this.reasonList = this.reasonList.concat(reasons);
      }.bind(
        this
      ),
      () => this.isProgress = false
    );
  }

  public dontSeeDoctorCont(event?: Event) {
    if (event) {
      event.preventDefault();
    }
  }

  private getDoctorAvailableDates(doctorId: number, date: string) {
    this.isCalenderLoading = true;
    this.doctorService.getDoctorAvailableTime(doctorId, date).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      response => {
        this.isPublicallyListed = response.length > 0 ? true : false;
        this.available_dates = response;
        this.isCalenderLoading = false;
      },
      (_error) => {
        this.isCalenderLoading = false;
      }
    );
  }

  // previous implementation of calender in wireframe
  public selectOrReleseTimeSlot(timeSlot: any, dateColumn: string, daySlot, event?: Event) {
    event.preventDefault();
    daySlot.blur();

    const indexOfTimeSlot = this.selectedTimeIndex(timeSlot, dateColumn);

    if (!this.caseForm.contains('doctor_availability')) {this.caseForm.addControl('doctor_availability', new FormArray([]));}

    if (indexOfTimeSlot < 0) {
      const selectedTimeObject: any = {};
      if (!timeSlot.is_reoccurring) {
        selectedTimeObject.startDateTime = timeSlot.timezone_available_date + ' ' + timeSlot.timezone_start_time;
        selectedTimeObject.endDateTime = timeSlot.timezone_available_date + ' ' + timeSlot.timezone_end_time;
        selectedTimeObject.utcStartDateTime = moment(selectedTimeObject.startDateTime).utc().format('YYYY-MM-DD HH:mm');
        selectedTimeObject.utcEndDateTime = moment(selectedTimeObject.endDateTime).utc().format('YYYY-MM-DD HH:mm');
      }
      else {
        selectedTimeObject.startDateTime = dateColumn + ' ' + timeSlot.timezone_start_time;
        selectedTimeObject.endDateTime = dateColumn + ' ' + timeSlot.timezone_end_time;
        selectedTimeObject.utcStartDateTime = moment(selectedTimeObject.startDateTime).utc().format('YYYY-MM-DD HH:mm');
        selectedTimeObject.utcEndDateTime = moment(selectedTimeObject.endDateTime).utc().format('YYYY-MM-DD HH:mm');
      }
      (this.caseForm.get('doctor_availability') as FormArray).push(new FormControl(selectedTimeObject));
    }
    else {
      const selectedTime = (this.caseForm.get('doctor_availability') as FormArray);
      selectedTime.removeAt(indexOfTimeSlot);
    }
  }

  // previous implementation of calender in wireframe
  private selectedTimeIndex(timeSlot, localDate) {

    const selectedTime = (this.caseForm.get('doctor_availability') as FormArray);
    const index = selectedTime.controls.findIndex(item => {
      const value = item.value;
      const startDateTime = value.startDateTime;
      const endDateTime = value.endDateTime;


      let timeSlotStartDateTime;
      let timeSlotEndDateTime;

      if (!timeSlot.is_reoccurring) {
        timeSlotStartDateTime = timeSlot.timezone_available_date + ' ' + timeSlot.timezone_start_time;
        timeSlotEndDateTime = timeSlot.timezone_available_date + ' ' + timeSlot.timezone_end_time;
      }
      else {
        timeSlotStartDateTime = localDate + ' ' + timeSlot.timezone_start_time;
        timeSlotEndDateTime = localDate + ' ' + timeSlot.timezone_end_time;
      }

      return (startDateTime === timeSlotStartDateTime) && (endDateTime === timeSlotEndDateTime);
    });

    return index;
  }

  // previous implementation of calender in wireframe
  public getNextDates(event?: Event) {
    event.preventDefault();

    const lastDateObject = this.available_dates.times[this.available_dates.times.length - 1];
    const lastDateMoment = moment(lastDateObject.date).add(1, 'day');

    this.getDoctorAvailableDates(this.selectedDoctor.doctor_info.doctor_id, lastDateMoment.format('YYYY/MM/DD'));
  }

  // previous implementation of calender in wireframe
  public getPrevDates(event?: Event) {
    event.preventDefault();

    const lastDateObject = this.available_dates.times[0];
    const lastDateMoment = moment(lastDateObject.date).subtract(4, 'day');

    this.getDoctorAvailableDates(this.selectedDoctor.doctor_info.doctor_id, lastDateMoment.format('YYYY/MM/DD'));
  }

  public cancelUpload(uploader: FileUploader, event?: Event) {
    if (event) { event.preventDefault(); }

    uploader.cancelAll();

    const index = this.uploads.findIndex(deleteUploader => deleteUploader.type == 'file' && deleteUploader.uploader.queue[0].file.name.toLowerCase() === uploader.queue[0].file.name.toLowerCase());
    this.uploads.splice(index, 1);

  }

  public deleteUpload(uploader: any, event?: Event) {
    if (event) { event.preventDefault(); }

    let fileName;

    try {
      fileName = uploader.uploader.queue[0].file.name;
    }
    catch (error) {
      fileName = 'this file';
    }

    const positiveAction = function () {
      if (typeof uploader === 'string') {

        var case_documents = this.caseForm.get('case_documents') as FormArray;

        if (case_documents) {
          var fileToDeleteIndex = case_documents.controls.findIndex(file => file.value.document_name.toLowerCase() === uploader.toLowerCase());

          if (fileToDeleteIndex < 0) {return;}

          case_documents.removeAt(fileToDeleteIndex);
        }

        var index = this.uploads.findIndex(deleteUploader => deleteUploader.type == 'link' && deleteUploader.link.document_name.toLowerCase() === uploader.toLowerCase());

        if (index < 0) {return;}

        this.uploads.splice(index, 1);


      }
      else {
        const documentNameToDelete = uploader.uploader.queue[0].file.name;
        const documentTypeToDelete = uploader.documentType;

        var case_documents = this.caseForm.get('case_documents') as FormArray;

        if (case_documents) {
          var fileToDeleteIndex = case_documents.controls.findIndex(file => file.value.document_name.toLowerCase() === documentNameToDelete.toLowerCase()
              && file.value.document_type.toLowerCase() === documentTypeToDelete.toLowerCase());
          case_documents.removeAt(fileToDeleteIndex);
        }

        var index = this.uploads.findIndex(deleteUploader => deleteUploader.uploader.queue[0].file.name.toLowerCase() === uploader.uploader.queue[0].file.name.toLowerCase()
            && deleteUploader.documentType.toLowerCase() === documentTypeToDelete.toLowerCase());
        this.uploads.splice(index, 1);
      }

      this.popUpService.hideDiv('confirmDialog');

      this.showAlert(
        fileName + ' has been deleted'
      );
    }.bind(this);

    this.showConfirmation(
      'Are you sure you want delete \'' + fileName + '\'?',
      positiveAction
    );


  }

  private getDocumentType() {

    const user = this.loginService.getCurrentUser();

    this.commonService.getCaseDocumentType(user.user_type.type).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      types => {
        const typesList = [{
          key: 'Select',
          value: 'SELECT'
        }];
        types = types.filter(each => each.value !== 'CONSULTATION SUMMARY TRANSLATED');

        this.types = typesList.concat(types);
        this.isDocumentTypeInprogress = false;
      },
      (_error) => {
        this.isDocumentTypeInprogress = false;
      }
    );
  }

  public uploadAddItem(type: string, event?: Event, document?: HTMLInputElement | NgForm, fileType?: HTMLInputElement, uploader?: FileUploader): void {
    if (event) {
      event.preventDefault();
    }

    const uploadObject = {};
    uploadObject['type'] = type;

    if (type == 'file' && document instanceof HTMLInputElement) {
      if (!fileType || fileType.value == null || fileType.value === 'SELECT'
        || uploader == null || uploader.queue.length < 1) {
        return;
      }

      uploadObject['uploader'] = uploader;
      uploadObject['documentType'] = fileType.value;

      document.value = null;
      fileType.value = 'SELECT';
      
      uploader.uploadAll();
      this.uploads.push(uploadObject);
      this.uploader = this.getUploader(this.case_id);
      this.addOptionToUploader(this.uploader);
    }
    else if (type == 'link' && document instanceof NgForm) {
      if (!document || !document.value.document_name || document.value.document_name == null || document.value.document_name == ''
        || !document.value.location || document.value.location == null || document.value.document_name === '') {
        return;
      }

      const documentObject = {};
      documentObject['document_name'] = document.value.document_name;
      documentObject['location'] = document.value.location;
      documentObject['size'] = null;
      documentObject['document_type'] = null;

      const case_documents = this.caseForm.get('case_documents') as FormArray;


      if (case_documents != null) {
        case_documents.push(new FormControl(documentObject));
      }

      uploadObject['link'] = documentObject;
      this.uploads.push(uploadObject);

      document.resetForm();
    }
  }

  public goToUrl(url: string, event) {
    event.preventDefault();

    this.router.navigate(['/redirect'], { queryParams: { openUrl: url } });
  }

  public inputFieldOnChange(input: HTMLInputElement) {
    if (input.value == null || input.value === '') {
      this.uploader.clearQueue();
      input.value == null;
    }
  }

  public containsCurrentDate(): boolean {
    const date = moment().format('YYYY-MM-DD');

    if (this.available_dates && this.available_dates != null) {
      const index = this.available_dates.times.findIndex(time => time.date === date);

      if (index > -1) {return true;}
    }
    return false;
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  public onDateChange(_event, calender, dateInput) {
    const date = moment(calender.selected).format('YYYY-MM-DD');
    const dateToSave = moment(calender.selected).format('YYYY-MM-DDTHH:mm:ssZ');
    this.caseForm.get('earliest_available_date').setValue(dateToSave);
    dateInput.value = date;
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();

        }
      }
    }

  }

  public selectOrUnselectRow(dateRow: any) {
    let selected = false;

    if (!dateRow.selected) {selected = false;}
    else {selected = dateRow.selected;}

    dateRow.selected = selected ? false : true;
  }

  getSelectedTime() {
    const selectedTime = this.available_dates.filter(dates => {
      if (!dates.selected) {return false;}
      else {return dates.selected;}
    });

    return selectedTime;
  }

  hideDocProfile(event?: Event) {
    if (event) {event.preventDefault();}

    this.doctorProfile.nativeElement.hidden = true;

    this.selectedDoctorAvatarImage$.unsubscribe();
    this.selectedDoctorInfo$.unsubscribe();
    this.selectedDoctorBoardCertification$.unsubscribe();
    this.selectedDoctorCondition$.unsubscribe();
  }

  onChange() {

    if (
      !(
        this.caseTypeField.value === 'VIDEO CONSULTATION'
        || this.caseTypeField.value === 'PATHOLOGY REVIEW'
        || this.caseTypeField.value === 'ELSE'
      )
    ) {
      this.caseForm.get('earliest_available_date').reset();

      this.caseForm.get('earliest_available_date').clearValidators();
      this.caseForm.get('earliest_available_date').updateValueAndValidity();
    }
    else {
      this.caseForm.get('earliest_available_date').setValidators(
        [Validators.required]
      );
      this.caseForm.get('earliest_available_date').updateValueAndValidity();
    }
  }

  changeAgreementShowAll(event, action) {
    if (event) {event.preventDefault();}

    if (action === 'read_more') {
      this.agreementShowAll = true;
    }
    else {
      this.agreementShowAll = false;
    }
  }

  public onPreferredModeSelect(value: string) {
    if (value === 'EMAIL') {
      this.best_communication_time.setValidators([]);
      this.best_communication_time.updateValueAndValidity();
      this.best_communication_time.setValue(null);

      this.phone_number.setValidators([]);
      this.phone_number.updateValueAndValidity();
      this.phone_number.setValue(null);
      return;
    }

    this.phone_number.setValidators([Validators.required]);
    this.phone_number.updateValueAndValidity();
    this.phone_number.setValue(null);

    this.best_communication_time.setValidators([Validators.required]);
    this.best_communication_time.updateValueAndValidity();
    this.best_communication_time.setValue(null);
  }

  get caseTypeField() {
    return this.caseForm.get('case_type');
  }

  get case_additional_note(): FormControl {
    return this.caseForm.get('case_additional_note') as FormControl;
  }

  get case_patient_note(): FormControl{
    return this.caseForm.get('case_patient_note') as FormControl;
  }

  get patient_diagnosis(): FormControl {
    return this.caseForm.get('patient_diagnosis') as FormControl;
  }

  get allyCaseNumber(): FormControl {
    return this.caseForm.get('allyCaseNumber') as FormControl;
  }


  get case_type(): FormControl {
    return this.caseForm.get('case_type') as FormControl;
  }

  get case_questions(): FormArray {
    return this.caseForm.get('case_questions') as FormArray;
  }

  get preferred_communication_mode(): FormControl {
    return this.caseForm.get('preferred_communication_mode') as FormControl;
  }

  get best_communication_time(): FormControl {
    return this.caseForm.get('best_communication_time') as FormControl;
  }

  get phone_number(): FormControl {
    return this.caseForm.get('phone_number') as FormControl;
  }

  get agreement(): FormControl {
    return this.caseForm.get('agreement') as FormControl;
  }
}
