<app-dialog></app-dialog>
<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Profile</h1>
	</div>
</div>

<div class="wrapper--dash-content dash-content">
	<div class="row">
		<div class="col__md-5 col-sm-6 single-centered-col">
			<div class="profile-card">

				<div class="profile-card__image">
					<div class="image-round">
						<img [src]="profilePicPath" *ngIf="!avatarimage" />
						<img [src]="'data:image/jpg;base64,'+avatarimage" *ngIf="avatarimage" />
					</div>
				</div>

				<div class="profile-card__name">{{user?.first_name}} {{user?.middle_name}} {{user?.last_name}}</div>
				<div class="profile-card__email">{{user?.email}}</div>

				<div class="profile-card__row">
					<div class="profile-card__label">Status</div>
					<div class="profile-card__details">
						<span *ngIf="!user?.is_deleted" class="btn btn--green btn--mini">Active</span>
						<span *ngIf="user?.is_deleted" class="btn btn--red btn--mini">Inactive</span>
					</div>
				</div>

				<div class="hr hr--space-down-x hr--space-up-x"></div>

				<div>
					<div class="profile-card__row">
						<div class="profile-card__label">Client</div>
						<div class="profile-card__details">
							<div class="profile-card__details-list">
								<span>{{user?.company_name}}</span>
							</div>
						</div>
					</div>
                </div>

				<div class="profile-card__row">
					<div class="profile-card__label">Phone Number</div>
					<div class="profile-card__details">
						{{user?.phone_number != null ? user?.phone_number: 'No Number'}}</div>
				</div>

				<div class="profile-card__btns">
					<!--Inactive user confirmation swal  -->
                    <a *ngIf="!user?.is_deleted && userType === 'admin'" [swal]="inactiveUserSwal" class="btn btn--red">Inactivate User</a>

					<swal #inactiveUserSwal icon="warning" iconColor="orange" title="In Activate User" [inputValidator]="validReason()"
						text="Are you sure you want to proceed ?" closeOnConfirm="true" input="textarea" closeOnCancel="true"
						backdrop="#eef3f6" cancelButtonColor="#db161e" inputPlaceholder="Enter Reason" reverseButtons="true" showCancelButton="true"
						confirmButtonColor="green" confirmButtonText="Inactivate User">
					
						<div class="form__field" *swalPortal>
							<swal-title>User to inactive: {{user.first_name }} {{user.middle_name }} {{user.last_name}} </swal-title>
							<swal-title>
								<hr>
							</swal-title>
							<swal-title class="mt-2 ml-0"><label>Please let us know why you are <span
										class="badge bg-secondary">inactivating</span> this user</label>
							</swal-title>
						</div>
					</swal>

					<swal #inactiveUserConfirmationSwal title="Confirmation" text="Are you sure you want to proceed ?"
						(confirm)="deleteUser()" closeOnConfirm="true" closeOnCancel="true" backdrop="#eef3f6"
						cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true" confirmButtonColor="green"
						confirmButtonText="Continue">
					</swal>


					<!-- Activated user Swal -->
                    <button *ngIf="user?.is_deleted && userType === 'admin'" class="btn btn--green" [swal]="activateUserSwal" >Activate User</button>
					
					<swal #activateUserSwal (confirm)="onRestore(userId)"  title="Activate Confirmation" 
					text="Do you want to activate {{user?.first_name }} {{user?.middle_name }} {{user?.last_name}} from the system ?" closeOnConfirm="true"closeOnCancel="true"
					backdrop="#eef3f6" cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true"
					confirmButtonColor="green" confirmButtonText="Continue">
					</swal>

                    <a href="javascript:void(0)" [routerLink]="['/edit/eligible-member',userId]" class="btn btn--blue">Edit Profile</a>


				</div>
			</div>
		</div>

	</div>
	<div class="col col__md-10 single-centered-col">
		<app-user-history [userId]="userId"></app-user-history>
	</div>
</div>